import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../utils/siteConfig'
import { graphql, Link } from 'gatsby'
import { css } from 'styled-components'

import { media } from '../utils/media'
import { space, fontSizes, mediaQueries, palette } from '../utils/tokens'

import SEO from '../components/SEO'

import { BlogCard } from '../components/Card/index'
import HeroNobackground from '../components/HeroNoBackground' 
import GatsbyImage from 'gatsby-image'
import Container from '../components/Container'

import { SectionHeading } from '../components/Aggregators/SectionHeading'
import { MainLayout } from '../components/MainLayout'

const LatestPosts = ({ posts }) => {
  return (
    <div>
      <SectionHeading title="Latest blog posts" />
      <ul
        css={css({
          listStyle: `none`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            gridGap: `${space[4]}px ${space[5]}px`,
          },
        })}
      >
        {posts &&
          posts.map(({ node }, i) => {
            if (i === 0) {
              return (
                <li
                  key={node.id}
                  css={css({
                    marginBottom: `${space[4]}px`,
                    [mediaQueries.lg]: {
                      gridRow: `1 / span 3`,
                      marginBottom: 0,
                    },
                  })}
                >
                  <div
                    css={css({
                      display: `flex`,
                      flexDirection: `column`,
                    })}
                  >
                    <Link
                      to={node.fields.path}
                      css={css({
                        display: `block`,
                        marginBottom: `${space[3]}px`,
                      })}
                    >
                      {node.coverPhoto && (
                        <GatsbyImage
                          fluid={node.coverPhoto.fluid}
                          alt={node.coverPhoto.title}
                        />
                      )}
                    </Link>
                    <Link
                      to={node.fields.path}
                      css={css({
                        fontSize: fontSizes[3],
                        fontWeight: `bold`,
                        display: `block`,
                        textDecoration: `none`,
                        marginBottom: `${space[3]}px`,
                        '&:hover': {
                          color: palette.orange[60],
                        },
                        [mediaQueries.lg]: {
                          fontSize: fontSizes[4],
                        },
                      })}
                    >
                      {node.title}
                    </Link>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          node.fields &&
                          node.fields.excerpt,
                      }}
                    />
                    <Link
                      to={node.fields.path}
                      css={css({
                        flex: 1,
                        color: palette.orange[70],
                        textDecoration: `none`,
                        display: `block`,
                        fontWeight: `500`,
                        marginBottom: `${space[1]}px`,
                        marginTop: `${space[3]}px`,
                        '&:hover': {
                          textDecoration: `underline`,
                          color: palette.orange[60],
                        },
                      })}
                    >
                      Read More »
                    </Link>
                  </div>
                </li>
              )
            } else {
              return (
                <li
                  key={node.id}
                  css={css({
                    gridColumn: `2`,
                    marginBottom: `${space[4]}px`,
                    [mediaQueries.lg]: {
                      marginBottom: 0,
                    }
                  })}
                >
                  <BlogCard
                    url={node.fields.path}
                    image={node.coverPhoto}
                    tags={node.tags}
                    title={node.title}
                    excerpt={
                      node.fields &&
                      node.fields.excerpt
                    }
                    date={node.originalPublishDate || node.createdAt}
                    excerptCss={css({
                      overflow: `hidden`,
                      textOverflow: `ellipsis`,
                      display: `-webkit-box`,
                      '-webkit-box-orient': `vertical`,
                      '-webkit-line-clamp': `2`,
                    })}
                    imageContainerCss={css({
                      [mediaQueries.lg]: {
                        width: `250px`,
                        height: `125px`,
                        minWidth: `unset`,
                        minHeight: `unset`,
                      },
                    })}
                    cardContentCss={css({
                      [mediaQueries.lg]: {
                        padding: `0 ${space[3]}px`,
                      },
                    })}
                  />
                </li>
              )
            }
          })}
      </ul>
    </div>
  )
}
const Blog = ({ data }) => {
  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  // we start from 4 since we're showing the 4 latest blog posts
  const moreBlogPosts = data.allContentfulBlog.edges.slice(4)
  const latestBlogPosts = data.latestBlogPosts.edges

  return (
    <div>
      <MainLayout>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO pagePath={slug} postNode={postNode} pageSEO />
        <main
          css={css({
            paddingTop: `${space[5]}px`,
          })}
        >
          <HeroNobackground hero={hero} />
          <div
            css={css({
              padding: `0 ${space[3]}px`,
              [mediaQueries.lg]: {
                padding: `0 ${space[5]}px`,
              },
            })}
          >
            <Container
              css={css({
                marginBottom: `${space[5]}px`,
                [mediaQueries.lg]: {
                  marginBottom: `${space[6]}px`,
                },
              })}
            >
              <LatestPosts posts={latestBlogPosts} />
            </Container>
            <Container
              css={css({
                marginBottom: `${space[5]}px`,
                [mediaQueries.lg]: {
                  marginBottom: `${space[6]}px`,
                },
              })}
            >
              <SectionHeading title="More blog posts" />
              <div
                css={css({
                  [mediaQueries.lg]: {
                    display: `grid`,
                    gridTemplateColumns: `3fr 1fr`,
                  },
                })}
              >
                <div
                  css={css`
                    display: grid;
                    grid-gap: ${space[4]}px;
                    margin-bottom: 2rem;
                    ${media.desktop`
                      grid-gap: ${space[5]}px;
                      margin-bottom: ${space[4]}px;
                    `}
                  `}
                >
                  {moreBlogPosts.map(blog => {
                    return (
                      <BlogCard
                        key={blog.node.id}
                        url={blog.node.fields.path}
                        image={blog.node.coverPhoto}
                        tags={blog.node.tags}
                        title={blog.node.title}
                        excerpt={blog.node.fields && blog.node.fields.excerpt}
                        date={
                          blog.node.originalPublishDate || blog.node.createdAt
                        }
                      />
                    )
                  })}
                </div>
                <div />
              </div>
            </Container>
          </div>
        </main>
      </MainLayout>
    </div>
  )
}

export default Blog

export const blogPageQuery = graphql`
  query BlogPageQuery {
    contentfulPage(slug: { eq: "blog" }) {
      hero {
        ...HeroFragment_withNoBackground
      }
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
    }
    latestBlogPosts: allContentfulBlog(
      sort: {
        fields: [publishDate, originalPublishDate, createdAt]
        order: DESC
      }
      limit: 4
    ) {
      edges {
        node {
          ...BlogCardFragment
          originalPublishDate(formatString: "MMMM DD, YYYY")
          createdAt(formatString: "MMMM DD, YYYY")
          fields {
            path
            excerpt
          }
        }
      }
    }
    allContentfulBlog(
      sort: {
        fields: [publishDate, originalPublishDate, createdAt]
        order: DESC
      }
    ) {
      edges {
        node {
          ...BlogCardFragment
          originalPublishDate(formatString: "MMMM DD, YYYY")
          createdAt(formatString: "MMMM DD, YYYY")
          fields {
            path
            excerpt
          }
        }
      }
    }
  }
`
